import Api from '.'

class Feedback extends Api {
	constructor() {
		super()
	}

	getAll({ page = 1, limit = 10 } = {}) {
		return this.get('winner-report', {
			params: {
				page,
				limit,
			},
		})
	}
}

export default Feedback
